import Router from '../router/index'
import url from './url'
import token from './token'

export default function (axios) {
  let appId = url.getUrlOption('appid')
  let clientSecret = url.getUrlOption('client_secret')
  let code = url.getUrlOption('code')
  if (appId) localStorage.setItem('appId', appId)
  if (code) localStorage.setItem('code', code)
  const $ajax = axios.create({
    baseURL: 'https://shhk-data-board.classin.site',//process.env.VUE_APP_REQUEST_BASE_URL, //
    timeout: 10000
  })
  // 请求头设置对应模块的token
  $ajax.interceptors.request.use((config) => {
    let tokenKey = localStorage.getItem('token_key') || ''
    let accessToken = localStorage.getItem('accessToken_' + tokenKey)
    config.headers['appId'] = appId || localStorage.getItem('appId') || '';
    config.headers['clientSecret'] = clientSecret || '';
    if (accessToken) {
      config.headers['token'] = accessToken
    }
    return config
  })
  $ajax.interceptors.response.use(
    (response) => {
      if (['W0001', 'W0009'].includes(response.data.result?.code)) {
        // 跳转对应模块的无权限页面
        let tokenKey = localStorage.getItem('token_key') || ''
        // 特殊页面处理
        if (tokenKey === 'courseList') {
          Router.push(`/datav/noAccess`)
        } else {
          Router.push(`/${tokenKey}/noAccess`)
        }
        token.removeToken()
        return Promise.reject(response.data)
      }
      return response
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  return $ajax
}
