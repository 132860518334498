export default {
  path: 'noAccess',
  // name: 'LiveNoAccess',
  name: 'noAccess',
  component: () => import('@/views/liveWindow/liveNoAccess'),
  meta: {
    title: '虹口教育直播窗',
    noAccess: true
  }
}
