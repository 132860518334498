import Vue from 'vue'
import VueRouter from 'vue-router'
import token from '../util/token'
import datavRouter from './datav'
import liveWindowRouter from './live-window'

import liveWindowIFrameRouter from './live-window-iframe'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'IndexPage',
    component: () => import('../views/index.vue'),
    redirect: 'datav',
    children: [datavRouter, liveWindowRouter, liveWindowIFrameRouter],
    meta: {
      title: '数字虹教'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const { title, noAccess } = to.meta
  document.title = title || ''
  if (noAccess) {
    return next()
  }
  // 不同模块获取不同tokenKey, 设置路由时matched中有且应只有一组路由元信息包含tokenKey
  // 多层路由同时存在tokenKey时只获取第一层tokenKey
  // 特殊页面需单独设置token时应设置对应路由参数, eg: classroomList/:tokenKey?
  if (to.params.tokenKey) {
    await token.setToken(to.params.tokenKey)
  } else {
    for (let i = 0; i < to.matched.length; i++) {
      let tokenKey = to.matched[i].meta.tokenKey
      if (tokenKey) {
        await token.setToken(tokenKey)
        break
      }
    }
  }
  next()
})

router.afterEach((to) => {
  history.replaceState(null, null, to.path)
})

export default router
