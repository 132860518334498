import liveNoAccessRouter from './live-no-access'
import liveListRouter from './liveList'

export default {
  path: 'liveWindow',
  name: 'LiveWindow',
  component: () => import('@/views/liveWindow'),
  redirect: 'liveWindow/liveList',
  children: [liveNoAccessRouter, liveListRouter],
  meta: {
    title: '虹口教育直播窗',
    tokenKey: 'liveWindow'
  }
}
