import Vue from 'vue'
import url from './url.js'
export default {
  getToken() {
    return new Promise((resolve, reject) => {
      let code = url.getUrlOption('code')
      Vue.prototype
        .$ajax({
          url: '/hongkou/auth/token',
          method: 'get',
          params: { code }
        })
        .then(({ data }) => {
          resolve(data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async setToken(key) {
    localStorage.setItem('token_key', key)
    let accessToken = localStorage.getItem('accessToken_' + key)
    let expirationTime = localStorage.getItem('expirationTime_' + key) || 0
    const curTime = +new Date()
    if (!accessToken || curTime >= expirationTime) {
      accessToken = await this.getToken()
      localStorage.setItem('accessToken_' + key, accessToken)
      localStorage.setItem('expirationTime_' + key, curTime + 86400000)
    }
  },
  removeToken() {
    let tokenKey = localStorage.getItem('token_key')
    let accessToken = localStorage.getItem('accessToken_' + tokenKey)
    if (tokenKey && accessToken) {
      localStorage.removeItem('token_key')
      localStorage.removeItem('accessToken_' + tokenKey)
      localStorage.removeItem('expirationTime_' + tokenKey)
    }
  }
}
