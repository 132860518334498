
export default {
  path: 'liveWindowIframe',
  name: 'liveWindowIframe',
  component: () => import('@/views/liveWindowIframe'),
  redirect: 'liveWindowIframe/liveList',
  children: [{
    path: 'liveList/:courseId?',
    name: 'liveList',
    component: () => import('@/views/liveWindowIframe/liveList'),
    meta: {
      title: '虹口教育直播窗'
    }
  }],
  meta: {
    title: '虹口教育直播窗',
    // tokenKey: 'liveWindowIframe'
  }
}
