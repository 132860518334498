export default {
  getUrlOption(name) {
    let options = location.search.substring(1).split('&')
    let optionsObj = {}
    options.forEach((item) => {
      let optionItem = item.split('=')
      optionsObj[optionItem[0]] = optionItem[1]
    })
    return optionsObj[name] || ''
  }
}
