import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import auth from './util/auth.js'
import dataV from '@jiaminghi/data-view'
import VueAnimateNumber from 'vue-animate-number'
import { Select, Option, Message, Scrollbar, Dialog, Tabs, TabPane, Steps, Step, Tooltip } from 'element-ui'
import './assets/styles/main.css'

// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core'
// 引入柱状图图表，图表后缀都为 Chart
import { LineChart, BarChart, PieChart } from 'echarts/charts'
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  DataZoomComponent
} from 'echarts/components'
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features'
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers'

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  DataZoomComponent,
  // 按需使用
  LineChart,
  BarChart,
  PieChart
])

Vue.use(dataV)
Vue.use(VueAnimateNumber)
Vue.use(Select).use(Option).use(Scrollbar).use(Dialog).use(Tabs).use(TabPane).use(Steps).use(Step).use(Tooltip)

const $ajax = auth(axios)

Vue.prototype.$echarts = echarts
Vue.prototype.$message = Message
Vue.prototype.$ajax = $ajax

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
