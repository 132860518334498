import overviewRouter from './overview'
import classroomListRouter from './classroom-list'
import noAccessRouter from './no-access'

export default {
  path: 'datav',
  name: 'Datav',
  component: () => import('@/views/datav'),
  redirect: 'datav/overview',
  children: [overviewRouter, classroomListRouter, noAccessRouter],
  meta: {
    title: '上海市虹口区ClassIn数字化教学',
    tokenKey: 'datav'
  }
}
